module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mautic/gatsby-browser.js'),
      options: {"plugins":[],"hostUrl":"https://marketing.arielfuggini.com","head":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"508204126759295"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/src/templates/post.tsx"}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2000,"quality":100,"backgroundColor":"transparent"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://arielfuggini.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-158716214-1","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview/**"],"sampleRate":100,"siteSpeedSampleRate":10},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Charter","Noe","Marat"],"urls":["/fonts/fonts.css"]}},
    }]
